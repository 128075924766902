import React, { useState, useEffect } from "react";
// import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import ApiComponent from "./ApiComponent";
import Modal from "./Modal";

const MapComponent = () => {
  const [viewport, setViewport] = useState({
    latitude: 30,
    longitude: 0,
    zoom: 4,
  });

  const [countries, setCountries] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  console.log(countries);
  useEffect(() => {
    const key = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    mapboxgl.accessToken = key;

    const markers = [];
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
    });

    const risks = [
      // {name:'kidnapping', icon:'/map icons/22.png', offset:[-75,0], icon_size:[]},
      // {name:'crime', icon:'/map icons/12.png', offset:[0,0], icon_size:[]},
      // {name:'civilUnrest', icon:'/map icons/66.png', offset:[75,0], icon_size:[]},

      // {name:'corruption', icon:'/map icons/66.png', offset:[-75,90], icon_size:[]},
      // {name:'publicHealth', icon:'/map icons/8.png', offset:[0,90], icon_size:[]},
      // {name:'terrorism', icon:'/map icons/33.png', offset:[75,90], icon_size:[]},

      // {name:'war', icon:'/map icons/44.png', offset:[-75,180], icon_size:[]},
      // {name:'sanctions', icon:'/map icons/10.png', offset:[0,180], icon_size:[]},
      // {name:'ipProtection', icon:'/map icons/65.png', offset:[75,180], icon_size:[]},

      {name:'kidnapping', icon:'/map icons/22.png', offset:[-75,-90], icon_size:[]},
      {name:'crime', icon:'/map icons/12.png', offset:[0,-90], icon_size:[]},
      {name:'civilUnrest', icon:'/map icons/66.png', offset:[75,-90], icon_size:[]},

      {name:'corruption', icon:'/map icons/66.png', offset:[-75,0], icon_size:[]},
      {name:'publicHealth', icon:'/map icons/8.png', offset:[0,0], icon_size:[]},
      {name:'terrorism', icon:'/map icons/33.png', offset:[75,0], icon_size:[]},

      {name:'war', icon:'/map icons/44.png', offset:[-75,90], icon_size:[]},
      {name:'sanctions', icon:'/map icons/10.png', offset:[0,90], icon_size:[]},
      {name:'ipProtection', icon:'/map icons/65.png', offset:[75,90], icon_size:[]},
    ]

    map.on("load", () => {
      risks.forEach(risk => {
        map.loadImage(risk.icon, function (err, image) {
          if(err) throw err;

          if(!map.hasImage(risk.name)) {
            map.addImage(risk.name, image);
          }
        })
      })

      let countryColors = [], 
      countryLabelFeatures = [];
      // console.log("Loading again");
      countries.forEach((country) => {
        let fillColor = "gray";
        const crisis = country.crisis;

        switch (crisis) {
          case "Minimal":
            fillColor = "lightblue";
            break;
          case "Low":
            fillColor = "skyblue";
            break;
          case "Medium":
            fillColor = "royalblue";
            break;
          case "High":
            fillColor = "navy";
            break;
          case "Critical":
            fillColor = "darkblue";
            break;

          default:
            break;
        }

        countryColors.push(country.cca3);
        countryColors.push(fillColor);

        // Assuming countryStats is an array of objects with iconPath and text properties
        if (country.countryStats.length > 0) {
          const customMarker = document.createElement("div");

          const iconList = document.createElement("ul");
          iconList.style.listStyle = "none";
          iconList.style.padding = "0";
          const icon = document.createElement("img");
          icon.src = "/status.png";
          icon.alt = "Marker Image";
          icon.style.width = "80px";
          icon.style.height = "80px";
          icon.style.opacity = "0";
          icon.style.zIndex = "1";
          country.countryStats.forEach((stat) => {
            if (stat.iconPath !== "" && stat.text !== "") {
              const listItem = document.createElement("li");

              const popIcon = document.createElement("img");
              popIcon.src = stat.iconPath;
              popIcon.alt = "Marker Image";
              popIcon.style.width = "15px";
              popIcon.style.height = "15px";
              listItem.appendChild(popIcon);

              const textDiv = document.createElement("div");
              textDiv.innerHTML = `<p>${stat.text}</p>`;
              listItem.appendChild(textDiv);

              iconList.appendChild(listItem);
            }
          });

          customMarker.appendChild(iconList);

          const marker = new mapboxgl.Marker({
            element: icon,
          })
            .setLngLat([country.latlng[1], country.latlng[0]])
            .setPopup(
              new mapboxgl.Popup({
                // className: 'custom-popup',
              }).setHTML(
                `<div style="max-height: 200px; overflow-y: auto;">` +
                  `<h5 style="font-size: medium; width:200px;">Country Stats</h5>${iconList.outerHTML}` +
                  `</div>`
              )
            )
            .setOffset([0, 0])
            .addTo(map);

          marker.getElement().addEventListener("click", () => {
            // Handle marker click event if needed
          });
        }

        countryLabelFeatures.push(
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [country.latlng[1], country.latlng[0]],
            },
            properties: {
              countryName: country.name.common,
              ...country
            },
          }
        );

      });

      map.addLayer({
        id: `country-boundaries`,
        source: {
          type: "vector",
          url: "mapbox://mapbox.country-boundaries-v1",
        },
        "source-layer": "country_boundaries",
        type: "fill",
        paint: {
          "fill-color": [
            'match',
            ['get', 'iso_3166_1_alpha_3'],
            ...countryColors,
            '#ccc'
          ],
          "fill-opacity": .7,
          "fill-outline-color": "white",
        }        
      });

      map.addLayer({
        id: `country-labels`,
        type: "symbol",
        source: {
          type: "geojson",
          data: {"type":"FeatureCollection", "features":[...countryLabelFeatures]},
        },
        layout: {
          "text-field": ["get", "countryName"],
          "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
          "text-size": 12,
          "text-anchor": "top",
        },
      });


      console.log(countryLabelFeatures);
      map.addSource("risk-rating", {
        type:"geojson",
        data: {"type":"FeatureCollection", "features":[...countryLabelFeatures]}
      });

      risks.slice(0,).forEach(risk => {
      
        map.addLayer({
          id:`risk-${risk.name}`,
          type:'symbol',
          source:'risk-rating',
          filter:['!=', `${risk.name}`, ""],
          layout:{
            'icon-image':risk.name,
            'icon-allow-overlap':true,
            'icon-padding':15,
            'icon-size':[
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.05,
              5,
              0.25,
              15,
              0.5
            ],
            'icon-offset':[...risk.offset]
          }
        });

      });


      console.log(map.getStyle().layers);
    });

    map.on("idle", () => {
      console.log("idle");
      setLoading(false);
    });

    return () => {
      map.remove();
    };
  }, [viewport, countries, initialLoadComplete]);

  return (
    <div id="map" style={{height:"100%", width:"100%"}} class="map-container" >
      <ApiComponent
        setCountryData={setCountryData}
        setCountries={setCountries}
        countryData={countryData}
      />

      {!loading && <div style={{ height: "1100px" }}></div>}

      {/* {loading && <Modal loading={loading} />} */}
    </div>
  );
};

export default MapComponent;