import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const spinnerStyle = css`
  display: block;
  margin: 0 auto;
`;

const ModalOverlay = (props) => {
  return (
    <div className={`${classes.modal}`}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const Modal = (props) => {
  const { loading } = props;
  const portalElement = document.getElementById("overlays");

  if (!portalElement) {
    console.error("Could not find portal element");
    return null;
  }

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>
          {loading ? (
            <>
              <p>Loading Map. Please Wait...</p>
              <RingLoader
                css={spinnerStyle}
                size={50}
                color={"#123abc"}
                loading={props.loading}
              />
            </>
          ) : (
            props.children
          )}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
