import './App.css';
import Footer from './components/Footer/Footer';
import MapComponent from './components/MapComponent';
import React from 'react';

function App() {
  return (
    <div className="App">
    
      <MapComponent />
      <Footer />
    </div>
  );
}

export default App;
