import React from "react";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Footer.module.css";


const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "#eeeeee",

        paddingBottom: "10px",
        paddingTop: "10px",
      }}
      class="accordion-container"
    >
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>View Map Guidence</Accordion.Header>
          <Accordion.Body>
            <h3 style={{ marginTop: "0px" }}>OVERALL COUNTRY RISK RATING</h3>
            <div className={styles.container}>
              <div className={styles.rightSec}>
                <div>
                  <div>
                    <Badge className="minorBadge" bg="lightblue">MINOR</Badge>
                    <p>
                      Minor risk locations generally have a historically stable
                      political and economic system with few or no prominent
                      security risks or threats to the governing structure
                      Issues such as crime demonstrations and unrest do not pose
                      any notable concern for visitors locals or general
                      business operations Protest actions are very rare and the
                      few that occur are minor in scale and non violent
                      Infrastructure and medical facilities are advanced and of
                      good quality with only occasional disruptions prompted by
                      natural or man made hazards
                    </p>
                  </div>
                  <div>
                    <Badge className="lowBadge" bg="skyblue">LOW</Badge>
                    <p>
                      Locations with a Low threat rating are typically
                      characterized as politically and socially stable with few
                      prominent security concerns Minor socio economic issues
                      can contribute to occasional incidents of civil unrest and
                      or protest actions although most such developments remain
                      non violent with few major associated disruptions to daily
                      life and business operations While crime is not a frequent
                      occurrence low levels of non violent crime may be present
                      Violent crime may occur on occasion generally in a major
                      urban area of the country Overall infrastructure and
                      medical facilities are advanced or of good quality with
                      occasional disruptions caused by natural and man made
                      hazards
                    </p>
                  </div>
                  <div>
                    <Badge className="mediumBadeg" bg="royalblue">MEDIUM</Badge>
                    <p>
                      Moderate risk locations experience periodic demonstrations
                      and protests with instances of violent civil unrest
                      possible These locations are typically politically stable
                      but developments such as socio economic issues may
                      periodically give rise to notable social tensions Rates of
                      crime are moderate though instances of violent crime occur
                      more frequently especially in major urban locations while
                      criminal incidents may occur in any area of the country
                      crime is not pervasive nationwide Overall safety and
                      security risks to individuals and businesses are moderate
                      Terrorism related concerns may be present but are not
                      frequent occurrences Ethnic and or sectarian issues may
                      exist but do not typically manifest in serious security
                      developments Infrastructure and medical care may be
                      inconsistent throughout the country although acceptable
                      levels can be found in the most prominent urban centers
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <Badge className="highBadge" bg="rgb(6, 6, 167)">HIGH</Badge>
                    <p>
                      Locations with high threat reating often
                      experienceelevated reates of crime, including violent
                      crime, that pose a concernto travelerss Political
                      instability concerns may be heightened and the type of
                      government in place may present itself as an issue to
                      foreign interests Countries may have weak economic
                      structures that contribute to elevated levels of unrest
                      and protest activity Demonstrations are common and may
                      arise in response to a multitude of issues response by
                      security forces may compound problems Terrorism ethnic and
                      or sectarian issues may also be a concern terrorism
                      related developments occur more frequently in High threat
                      destinations Localized militia or anarchist activity may
                      also occur in these locations The risk of or ongoing
                      conflict may be an issue in High threat environments
                      Overall issues pose a notable risk to foreign travelers
                      and businesses as well as the local populace Overall
                      medical care facilities and infrastructure are poor and
                      unreliable and many foreigners would need medical
                      evacuation for serious health issues
                    </p>
                  </div>
                  <div>
                    <Badge className="criticalBadge" bg="darkblue">CRITICAL</Badge>
                    <p>
                      The security and or political environment poses a
                      significant and direct threat to travelers and business
                      operations Locations rated as Extreme have unstable
                      securityenvironments, with political instability often a
                      major and president risk. The nature of the government may
                      pose a significant risk to foreign interests and state
                      sponsorship of surveillance and or censorship may be a
                      notable concern particularly outside urban centers where
                      general lawlessness may exist in the absence of an
                      adequate security apparatus Quality of infrastructure or
                      lack thereof may also present a serious issue Medical
                      facilities and care may be very poor to non existent in
                      portions of the country and those that exist are
                      significantly below international standards even for basic
                      medical care Most health issues would require external
                      medical support and any major medical emergency would
                      likely require evacuatio
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.leftSec}>
                <Container>
                  <Row className="mb-2 footerElements">
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/66.png" alt="icon" />
                        <div>
                          <h6>CIVIL UNREST</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/12.png" alt="icon" />
                        <div>
                          <h6>CRIME</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop ri
                          </p>
                        </div>
                      </span>
                    </Col>
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/55.png" alt="icon" />
                        <div>
                          <h6>CORRUPTION</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-2 footerElements">
                    <Col sm className="footerSecond">
                      <span className={styles.spanElement}>
                        <img src="/map icons/8.png" alt="icon" />
                        <div>
                          <h6>PUBLIC HEALTH</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/44.png" alt="icon" />
                        <div>
                          <h6>CONFLICT/WAR</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/33.png" alt="icon" />
                        <div>
                          <h6>TERRORISM</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-2 footerElements">
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/22.png" alt="icon" />
                        <div>
                          <h6>KIDNAPPING</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/10.png" alt="icon" />
                        <div>
                          <h6>SANCTIONS/EXPORT</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                    <Col sm>
                      <span className={styles.spanElement}>
                        <img src="/map icons/65.png" alt="icon" />
                        <div>
                          <h6>IP PROTECTION</h6>
                          <p>
                            Gillabefec ocurs rei in auc rem strae tumeri pero
                            inatum gjrhy wuid vjop rip
                          </p>
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Footer;
