import React, { useEffect } from "react";

const ApiComponent = ({ setCountryData, setCountries, countryData }) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryDataResponse = await fetch(
          "https://be-csc-map.mohamedradwan.me/api/"
        );

        const countriesResponse = await fetch(
          "https://restcountries.com/v3.1/all"
        );

        if (!countryDataResponse.ok || !countriesResponse.ok) {
          throw new Error("Failed to fetch data");
        }

        const countryData = await countryDataResponse.json();
        const countries = await countriesResponse.json();

        const crisis24RiskLevel =
          countryData.Items?.map((item) => {
            const countryStatsData = item["Country Stats"]?.L || [];
            return {
              country: item["Country"]?.S || "",
              crisis: item["Crisis24 Risk Level  #1 3/7/23"]?.S || "",
              kidnapping: item["Kidnapping ( State Dept) "]?.S || "",
              crime:
                item[
                  "Crime (Crime index over 50% based on population and other factors ) #9"
                ]?.S || "",
              civilUnrest: item["Civil Unrest"]?.S || "",
              corruption:
                item[
                  "Corruption (Civil & Government) *Based on % of Corruption below 50%)"
                ]?.S || "",
              publicHealth: item["Public Health GHS Ranking #7"]?.N
                ? Number(item["Public Health GHS Ranking #7"]?.N)
                : "",
              war: item["Conflict (War/Failed State)"]?.S || "",
              terrorism: item["Terrorism #11"]?.S || "",
              sanctions: item["Sanctions & Export Control"]?.S || "",
              ipProtection: item["IP Protection "]?.S || "",
              countryStats: countryStatsData.map((stat) => ({
                iconPath: stat.M.iconPath.S || "",
                text: stat.M.text.S || "",
              })),
            };
          }) || [];

        const mergedCountries = countries.map((country) => {
          const countryName = country.name.common;
          const countryDataItem = crisis24RiskLevel.find(
            (item) => item.country.toLowerCase() === countryName.toLowerCase()
          );

          return {
            ...country,
            crisis: countryDataItem?.crisis || "",
            kidnapping: countryDataItem?.kidnapping || "",
            crime: countryDataItem?.crime || "",
            civilUnrest: countryDataItem?.civilUnrest || "",
            corruption: countryDataItem?.corruption || "",
            publicHealth: countryDataItem?.publicHealth || "",
            war: countryDataItem?.war || "",
            sanctions: countryDataItem?.sanctions || "",
            terrorism: countryDataItem?.terrorism || "",
            ipProtection: countryDataItem?.ipProtection || "",
            countryStats: countryDataItem?.countryStats || [],
          };
        });

        setCountryData(crisis24RiskLevel);
        setCountries(mergedCountries);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    if (countryData.length === 0) {
      fetchData();
    }
  }, [setCountryData, setCountries]);

  return null;
};

export default ApiComponent;
